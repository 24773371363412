/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { motion, useAnimation } from 'framer-motion'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import * as Pricing from '../components/pages/pricing/styled.en'
import { Button, RegisterButton, Toggle } from '../components/UI'
import FeatureComparison, { HeadlessTippy } from '../components/pages/pricing/featureComparison.en'
import { reviewStructuredData } from '../components/layout/meta/schema-org'
import { features } from '../components/pages/pricing/data/features.en'
import plans from '../components/pages/pricing/data/plan-data.en'

/**
 * Helpers
 */
const fallback = (check, value) => (check ? value : '\u00a0')
const useComma = (string) => string.toString().replace('.', ',')

const Phone = ({ style }) => (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
        <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
    </svg>
)

/**
 * Tailwind styles.
 */
const styles = {
    menu: {
        container: tw`flex mb-4 space-x-2 relative rounded-md bg-white p-2 shadow-md`,
        button: tw`flex-1 antialiased font-normal text-sm tracking-tight py-2 w-full appearance-none border-0 rounded-md focus:ring focus:ring-indigo-500`,
    },
    plan: {
        label: tw`px-4 py-2 mb-4 bg-tellow-purple bg-opacity-10 rounded-lg text-sm antialiased font-medium tracking-tight text-tellow-purple items-center justify-center flex`,
        title: tw`text-3xl capitalize font-bold antialiased tracking-tight mb-0 pt-2 text-gray-800`,
        price: tw`text-2xl font-medium tracking-tight mb-0 relative text-tellow-purple`,
        per_month_excl: tw`text-xs font-light text-tellow-purple antialiased pt-0 pb-2 mb-0 mt-0!`,
        extends: tw`p-2`,
        interval: tw`mb-0 text-xs font-medium`,
        vat: tw`mb-0 text-xs antialiased`,
        footer: tw`mt-auto! flex flex-col`,
        list: {
            container: tw`m-0 list-none -ml-2`,
            item: tw`font-normal text-xs text-gray-600`,
        },
    },
    disclaimer: tw`text-center bg-gray-50 text-gray-500 p-2 rounded-lg mb-4`,
}

/**
 * Small components / icons.
 */
const BookkeeperHeadInACircle = () => (
    <>
        <Pricing.Bookkeeper />
        <Pricing.Online />
    </>
)

const SwipeIcon = () => (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(0.5,0,0,0.5,0,0)">
            <path
                d="M10.608,20.748,6.819,17.655a1.639,1.639,0,0,1-.566-1.676h0a1.638,1.638,0,0,1,2.321-1.068l1.174.587V8.748a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5v4.5l1.993.333a3,3,0,0,1,2.507,2.959v4.208"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path d="M0.748 7.248L5.998 7.248" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M4.498 3.498L0.748 7.248 4.498 10.998" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M15.748 7.248L23.248 7.248" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M19.498 3.498L23.248 7.248 19.498 10.998" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </g>
    </svg>
)

/**
 * Main page.
 */
const PricingPage = () => {
    const [isMonthly, setToMonthly] = useState(false)
    const featureRef = useRef(null)
    const controls = useAnimation()
    const timeouts = []

    useEffect(() => {
        timeouts.push(
            setTimeout(() => {
                controls.start({ opacity: 1, y: '0px', x: '-50%' })
            }, 500)
        )

        timeouts.push(
            setTimeout(() => {
                controls.start({ opacity: 0, y: '20px', x: '-50%' })
            }, 3500)
        )

        return () => {
            timeouts.forEach((timeout) => clearTimeout(timeout))
        }
    }, [])

    return (
        <Layout>
            <Meta
                keywords={['inexpensive', 'price', 'zzp', 'bookkeeping', 'accounting', 'cost', 'subscription', 'cheap']}
                title="The costs of our online accounting program | Tellow.nl"
                description="Fast online bookkeeping with the online bookkeeping program for freelancers ✅ 100,000+ entrepreneurs aboard ✅ Register for free!"
                path="/en/prijs"
                jsonLD={reviewStructuredData}
            />

            <Pricing.Boundary>
                <Pricing.FullWidthCentered>
                    <Pricing.ToggleBoundary>
                        <Toggle options={['Monthly', 'Annually']} value={isMonthly} onChange={(value) => setToMonthly(value)} />
                    </Pricing.ToggleBoundary>
                    <Pricing.Copy onClick={() => setToMonthly(false)} variant="sm">
                        Save up to 45%
                    </Pricing.Copy>
                </Pricing.FullWidthCentered>

                <Pricing.OverlayedNotification animate={controls} initial={{ opacity: 0, y: '20px', x: '-50%' }} transition={{ ease: 'easeInOut', duration: 0.65 }}>
                    <SwipeIcon />
                    <p>Swipe / scroll for more</p>
                </Pricing.OverlayedNotification>

                <Pricing.PlanContainer layout>
                    {Object.entries(plans).map(([key, plan], idx) => (
                        <Pricing.Plan key={idx}>
                            <Pricing.Label>{plan.label}</Pricing.Label>
                            <Pricing.PlanHeader locked={idx === 3 && isMonthly}>
                                <Pricing.Padding>
                                    <h3 css={styles.plan.title}>{key}</h3>
                                    <h4 css={styles.plan.price}>{fallback(plan.active, `€ ${useComma((isMonthly ? plan.priceMonthly : plan.priceYearly) / 100)}`)}</h4>

                                    {idx === 3 && <BookkeeperHeadInACircle />}

                                    <Pricing.PlanHeaderCopy>
                                        {plan.order === 1 ? (
                                            <p className="interval">No bank account or creditcard required.</p>
                                        ) : (
                                            <p className="interval">Pay per month, excl. 21% VAT</p>
                                        )}

                                        {!isMonthly && (
                                            <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="savings">
                                                {plan.savings ? `Save € ${plan.savings},- annually` : '\u00a0'}
                                            </motion.p>
                                        )}
                                        {isMonthly && <p className="savings">{'\u00a0'}</p>}
                                        <p className="introduction">{plan.introduction}</p>
                                    </Pricing.PlanHeaderCopy>

                                    {/* Book demo call */}
                                    {idx === 3 && (
                                        <div style={{ minHeight: '2.5rem' }}>
                                            <Button
                                                lightPurple
                                                target="_blank"
                                                href="https://calendly.com/tellow-compleet/compleet-informatie"
                                                style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}
                                            >
                                                <Phone style={{ height: '1rem', marginRight: '.5rem' }} />
                                                Book free call
                                            </Button>
                                        </div>
                                    )}

                                    {/* Spacer to even out table in case there is no demo button */}
                                    {idx !== 3 && <div style={{ minHeight: '2.5rem' }}>&nbsp;</div>}

                                    {(idx !== 3 || (idx === 3 && !isMonthly)) && (
                                        <RegisterButton medium purple context="price" style={{ textAlign: 'center', marginTop: '.5rem', width: '100%' }}>
                                            {plan.CTA} <span arrow="true">→</span>
                                        </RegisterButton>
                                    )}

                                    {idx === 3 && isMonthly && (
                                        <Button
                                            medium
                                            disabled
                                            context="price"
                                            onClick={() => setToMonthly(false)}
                                            style={{ textAlign: 'center', marginTop: '1rem', width: '100%' }}
                                        >
                                            Only available annually
                                        </Button>
                                    )}
                                </Pricing.Padding>
                            </Pricing.PlanHeader>

                            <Pricing.Padding style={{ height: '100%' }}>
                                <ul css={styles.plan.list.container}>
                                    {plan.extends && (
                                        <Pricing.ListItem css={[styles.plan.list.item, tw`text-tellow-purple font-semibold antialiased`]}>
                                            {fallback(plan.extends, `All features from ${plan.extends} +`)}
                                        </Pricing.ListItem>
                                    )}

                                    {plan.perks?.map((perk, index) => (
                                        <Pricing.ListItem css={styles.plan.list.item} key={`${plan}${index}`}>
                                            {typeof perk === 'string' && perk}
                                            {typeof perk === 'object' && perk.text}
                                            {typeof perk === 'object' && <HeadlessTippy text={perk.tooltip} />}
                                        </Pricing.ListItem>
                                    ))}
                                </ul>

                                <Pricing.Disclaimer>
                                    {plan.disclaimer?.map((disclaimer, index) => (
                                        <li key={`Disclaimer-${index}`} css={tw`ml-0 antialiased flex items-center text-gray-500 mb-2`}>
                                            <span css={tw`inline-block w-4 font-bold`}>{'*'.repeat(index + 1)}</span>
                                            {disclaimer}
                                        </li>
                                    ))}
                                </Pricing.Disclaimer>
                            </Pricing.Padding>
                        </Pricing.Plan>
                    ))}
                </Pricing.PlanContainer>
            </Pricing.Boundary>

            <Pricing.HideOnMobile>
                <Pricing.FullWidthCentered>
                    <Button medium onClick={() => document.querySelector('#matrix').scrollIntoView({ behavior: 'smooth' })}>
                        Compare all features ↓
                    </Button>
                </Pricing.FullWidthCentered>
            </Pricing.HideOnMobile>

            <FeatureComparison id="matrix" features={features} ref={featureRef} isMonthly={isMonthly} />
        </Layout>
    )
}

export default PricingPage
