import config from '../../../../config'

export const TRANSACTIONS_PER_MONTH = 25
export const RECEIPTS_PER_MONTH = 20
export const INVOICES_PER_MONTH = 3

/**
 * Data to map over.
 * Obtained from René.
 */
export const features = [
    {
        section: 'Invoicing',
        fns: [
            { title: 'Create and send invoices', activeInPlans: [`${INVOICES_PER_MONTH} per month`, 'unlimited', 'unlimited', 'unlimited'] },
            {
                title: 'Send payment requests along with invoice (costs only with payment)',
                activeInPlans: [
                    '0,5% <span>commission</span> <wbr/> + €0,29 <span>per payment</span>',
                    '0,25% <span>commission</span> <wbr/> + €0,29 <span>per payment</span>',
                    '€0,29 <span>per payment</span>',
                    '€0,29 <span>per payment</span>',
                ],
                tooltip: 'Send payment links along with invoices and get paid faster.',
            },
            { title: 'Automatic invoice reminders', tooltip: 'Use multiple professionally styled templates.', activeInPlans: [false, true, true, true] },
            { title: 'Professional invoice templates', activeInPlans: [false, true, true, true] },
            {
                title: 'Invoices in your style',
                activeInPlans: [false, true, true, true],
                tooltip: "Invoices in your own style, with your own logo in your company's colors.",
            },
            { title: 'No Tellow watermark', activeInPlans: [false, false, true, true] },
            { title: 'Validating customers in KVK database', activeInPlans: [false, false, true, true] },

            { title: 'Download invoices as PDF', activeInPlans: [true, true, true, true] },
            { title: 'English invoices', tooltip: 'With the click of a button send your invoice in English.', activeInPlans: [true, true, true, true] },
            { title: 'Overview paid and unpaid invoices', activeInPlans: [true, true, true, true] },
        ],
    },
    {
        section: 'Hour Registration',
        fns: [
            { title: 'Track hours with app', activeInPlans: [true, true, true, true] },
            { title: 'Convert directly to invoice', activeInPlans: [true, true, true, true] },
            { title: 'Create and manage projects', activeInPlans: [true, true, true, true] },
            { title: 'Hours per customer and project', activeInPlans: [true, true, true, true] },
            { title: 'Extensive hourly reporting', activeInPlans: [true, true, true, true] },
            { title: 'Insight into hour criterion', activeInPlans: [true, true, true, true] },
            { title: 'Track non-reimbursable hours', activeInPlans: [true, true, true, true] },
            {
                title: 'Download hourly specifications',
                activeInPlans: [true, true, true, true],
                tooltip: 'In PDF or CSV.',
            },
        ],
    },
    {
        section: 'Mileage registration',
        fns: [
            { title: 'Business trip tracking', activeInPlans: [true, true, true, true] },
            { title: 'Private trip tracking', activeInPlans: [true, true, true, true] },
            { title: 'Extended reports', activeInPlans: [true, true, true, true] },
            { title: 'Convert directly to invoice', activeInPlans: [true, true, true, true] },
        ],
    },
    {
        section: 'Banking',
        fns: [
            {
                title: 'Automatic loading of transactions',
                activeInPlans: [`${TRANSACTIONS_PER_MONTH} per month`, 'unlimited', 'unlimited', 'unlimited'],
                tooltip: 'No more importing transactions, no more retyping in Excel. With the click of a button, everything is loaded via a secure connection.',
            },
            { title: 'Linking expenses and revenues', activeInPlans: [true, true, true, true] },
            { title: 'Financial dashboard', activeInPlans: [true, true, true, true] },
            {
                title: 'Linking multiple bank accounts',
                activeInPlans: [false, false, true, true],
                tooltip: 'If you use more than one business account for your business we can also link them, for example a savings account.',
            },
            { title: 'Business loans', activeInPlans: [true, true, true, true] },
            { title: 'Invoice financing', activeInPlans: [true, true, true, true] },
        ],
    },
    {
        section: 'Administration',
        fns: [
            { title: 'Scan receipts', activeInPlans: [`${RECEIPTS_PER_MONTH} per month`, 'unlimited', 'unlimited', 'unlimited'] },
            { title: 'Saving receipts digitally', activeInPlans: [`${RECEIPTS_PER_MONTH} per month`, 'unlimited', 'unlimited', 'unlimited'] },
            { title: 'Digital financial statements', activeInPlans: [false, false, false, 'On request'] },
            { title: 'Overview of income and expenses', activeInPlans: [true, true, true, true] },
            { title: 'Booking expenses ', activeInPlans: [true, true, true, true] },
            { title: 'Profit and loss account', activeInPlans: [true, true, true, true] },
            { title: 'Useful address book', activeInPlans: [true, true, true, true] },
            { title: 'Automatic booking', activeInPlans: [true, true, true, true] },
            { title: 'Overview in VAT return', activeInPlans: [true, true, true, true] },
        ],
    },
    {
        section: 'Quotations',
        fns: [
            { title: 'Create and send quotations', activeInPlans: ['Free trial available', 'Free trial available', 'unlimited', 'unlimited'] },
            { title: 'Using your own corporate identity and logo', activeInPlans: [false, false, true, true] },
            { title: 'Automatically turn quotations into invoices', activeInPlans: [false, false, true, true] },
            { title: 'No Tellow footer', activeInPlans: [false, false, true, true] },
            { title: 'Easily add additional documents', activeInPlans: [false, false, true, true] },
        ],
    },
    {
        section: 'Tax',
        fns: [
            {
                title: 'VAT calculations',
                activeInPlans: [true, true, true, true],
            },
            {
                title: 'Manual VAT return submission',
                activeInPlans: [true, true, true, true],
                tooltip: 'We calculate all the numbers and set it up for you to type over, but you are responsible for submitting on time.',
            },
            {
                title: 'Automatic submission of VAT return',
                activeInPlans: [false, false, true, true],
                tooltip:
                    "Our hassle-free module, we do everything for you. So you have no stress, you're always on time and you make no mistakes. Available for sole proprietors only.",
            },
            {
                title: 'Preparation income tax (IB)',
                activeInPlans: [false, false, false, true],
                tooltip: 'There may be additional fees if there is a box 3, if you bought a house, or if you have multiple businesses.',
            },
            {
                title: 'IB tax return for your tax partner',
                activeInPlans: [false, false, false, true],
                tooltip: 'There may be additional fees if your partner is also an entrepreneur (freelancer or director and major shareholder (DGA)).',
            },
            { title: 'Automatic submission of IB tax return', activeInPlans: [false, false, false, true] },
            { title: 'Check by bookkeeper on VAT return', activeInPlans: [false, false, false, true] },
            { title: 'Check by bookkeeper on income tax return', activeInPlans: [false, false, false, true] },
        ],
    },
    {
        section: 'Expert support and advice',
        fns: [
            {
                title: 'Product support',
                activeInPlans: [true, true, true, true],
                tooltip: `Our team is here for you, on weekdays from ${config.support.opensAtTime} to ${config.support.closesAtTime} hours and by appointment also on weekends.`,
            },
            {
                title: 'Live chat',
                activeInPlans: [false, true, true, true],
                tooltip: `Our team is here for you, on weekdays from ${config.support.opensAtTime} to ${config.support.closesAtTime} hours and by appointment also on weekends.`,
            },
            {
                title: 'Phone',
                activeInPlans: [false, false, true, true],
                tooltip: `Our team is here for you, on weekdays from ${config.support.opensAtTime} to ${config.support.closesAtTime} hours and by appointment also on weekends.`,
            },
            {
                title: 'Access our informative webinars',
                activeInPlans: [true, true, true, true],
                tooltip: 'End of each quarter.',
            },
            {
                title: 'Unlimited accounting advice',
                activeInPlans: [false, false, false, true],
                tooltip: 'Our advisors and accountants can answer all your accounting questions.',
            },
            {
                title: 'An accountant for all your questions',
                activeInPlans: [false, false, false, true],
            },
            {
                title: 'An on-site startup meeting with an accountant',
                activeInPlans: [false, false, false, true],
            },
        ],
    },
    {
        section: 'Additional services',
        fns: [
            {
                title: 'Free startup consultation',
                activeInPlans: [true, true, true, true],
                tooltip: 'Available for booking on weekdays, evenings and weekends.',
            },
            {
                title: 'Free switching service',
                activeInPlans: [false, true, true, true],
                tooltip: 'Bookable on weekdays, evenings and weekends.',
            },
            {
                title: 'First access to beta features',
                activeInPlans: [false, true, true, true],
            },
            {
                title: 'iOS and Android app',
                activeInPlans: [true, true, true, true],
            },
            {
                title: 'The best deals for freelancers',
                activeInPlans: [false, true, true, true],
                tooltip: 'We have negotiated the best deals for you. That way you have even more benefits.',
            },
            {
                title: 'Finding your own accountant',
                activeInPlans: [true, true, true, 'Bookkeeper included'],
            },
            {
                title: 'Tax return alert',
                activeInPlans: [true, true, true, 'Message from accountant'],
                tooltip: 'Be automatically notified of deadlines and important changes in the law.',
            },
        ],
    },
    {
        section: 'Users',
        fns: [
            { title: 'Multiple user profiles', activeInPlans: [false, false, true, true] },
            { title: 'Bookkeeper/accountant portal', activeInPlans: [false, false, true, true] },
        ],
    },
    {
        section: 'Security',
        fns: [
            {
                title: 'SSO authentication (secured)',
                activeInPlans: [true, true, true, true],
                tooltip:
                    'Single sign-on software (SSO for short) allows end users to log in once after which access is automatically provided to multiple applications. This significantly increases the security level of Tellow, while requiring little extra effort on your part as a user!',
            },
            { title: 'Receipts and invoices stored digitally on our secure servers', activeInPlans: [true, true, true, true] },
            { title: 'Data kept encrypted on our secure servers', activeInPlans: [true, true, true, true] },
            { title: 'Verified integration with the Belastingdienst', activeInPlans: [false, false, true, true] },
        ],
    },
]
