import styled from 'styled-components'
import { motion } from 'framer-motion'
import { size, device } from '../../../utils/styled-components/breakpoints'
import bookkeeper from '../../../images/pricing/bookkeeper.png'

export const ListItem = styled.li`
    margin: 0.5rem;
    font-size: ${({ theme }) => theme.font.size.xs};
    list-style: none;
    position: relative;
    min-height: 2.125rem;
    padding: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
        font-size: ${({ theme }) => theme.font.size.xxs};
        font-weight: ${({ theme }) => theme.font.weight.bold};
        vertical-align: top;
    }

    p {
        margin: 0;
    }
`

export const Boundary = styled.div`
    width: 100vw;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    background: white;

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    margin-top: 0;
    @media ${device.mobileL} {
        margin-top: 2rem;
    }
`

export const FullWidthCentered = styled.div`
    width: 100%;
    display: grid;
    place-content: center;
    margin-top: 15px;
`

export const HideOnMobile = styled.section`
    max-height: 0;
    visibility: hidden;

    @media (max-width: ${size.mobileL}) {
        display: none;
    }

    @media ${device.mobileL} {
        max-height: unset;
        visibility: visible;
    }
`

export const ToggleBoundary = styled.div`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`

export const PlanContainer = styled(motion.section)`
    display: grid;
    margin: 1rem auto;
    position: relative;
    grid-template-columns: repeat(4, 1fr);

    /* Sticky scroll */
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    overflow-x: auto;
    width: inherit;

    /* Works on Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: blue orange;
    }

    padding-left: 3rem;
    padding-right: 3rem;

    transition: filter 350ms ease-out;

    max-width: unset;

    @media ${device.mobileL} {
        max-width: 80rem;
        overflow: unset;
    }

    @media screen and (max-width: ${size.tabletL}) {
        display: flex;
        flex-direction: column;

        overflow: initial;
        padding-left: 1rem;
        padding-right: 1rem;
    }
`

export const Plan = styled(motion.dl)`
    /* Sticky scroll */
    width: 100vw;
    min-width: 300px;
    scroll-snap-align: center;

    @media ${device.mobileL} {
        width: auto;
    }

    /* On mobile, switch to 'normal' cards */
    @media screen and (max-width: ${size.tabletL}) {
        width: 100%;
        min-width: unset;
        scroll-snap-align: unset;

        padding-top: 1.5rem;

        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
        border-radius: 1rem;

        &:not(:first-of-type) {
            border-top: 2px solid ${({ theme }) => theme.color.tellowGray200};
        }
    }

    & + & > div {
        border-left: 2px solid ${({ theme }) => theme.color.tellowGray200};

        @media screen and (max-width: ${size.tabletL}) {
            border-left: 0;
        }
    }
`

export const PlanHeader = styled.div`
    width: 100%;
    position: relative;
    border-bottom: 2px solid ${({ theme }) => theme.color.tellowGray200};

    > * > *:not(h3):not(a) {
        opacity: ${(props) => (props.locked ? 0.4 : 1)};
        transition: opacity 350ms ease-in-out;
        will-change: opacity;
    }
`

export const Padding = styled.div`
    padding-bottom: ${(props) => props.amount || '1.5rem'};
    padding-right: ${(props) => props.amount || '1.5rem'};
    padding-left: ${(props) => props.amount || '1.5rem'};
    padding-top: 1rem;
    position: relative;
`

export const Bookkeeper = styled.span`
    position: absolute;
    right: 1rem;
    top: 2rem;

    width: 3rem;
    height: 3rem;
    background: url(${bookkeeper});
    border: 1px solid ${({ theme }) => theme.color.tellowGray100};
    box-shadow: inset 0 0 5px 0 rgb(52 58 64 / 2.5%);
    background-position: 50% 0%;
    background-size: 100%;
    border-radius: ${({ theme }) => theme.radii.border.full};
`

export const Online = styled.span`
    position: absolute;
    right: calc(1rem + 2.125rem);
    top: 2.125rem;
    min-width: 0.65rem;
    min-height: 0.65rem;
    background: #67ec16;
    border-radius: ${({ theme }) => theme.radii.border.full};
    display: block;

    transition: transform border 150ms ease-in-out;
    animation: pulse 1s ease-in-out alternate infinite;

    @keyframes pulse {
        from {
            transform: scale(1);
            border: 1px solid white;
        }
        to {
            transform: scale(1.05);
            border: 1px solid transparent;
        }
    }
`

export const PlanHeaderCopy = styled.div`
    display: flex;
    margin: 0.5rem 0;
    flex-direction: column;

    > p.interval {
        margin: 0;
        font-size: 0.7rem;
        line-height: 1.35;
    }

    > p.savings {
        color: ${({ theme }) => theme.color.tellowPurple};
        font-size: ${({ theme }) => theme.font.size.xs};
        line-height: 1.5;
        margin: 0;
    }

    > p.interval {
        color: ${({ theme }) => theme.color.tellowPurple};
        opacity: 0.5;
    }

    > p.introduction {
        min-height: 5rem;
        margin-top: 1.5rem;
        line-height: 1.35;
        font-size: ${({ theme }) => theme.font.size.sm};
        color: ${({ theme }) => theme.color.tellowGray600};
    }
`

export const Disclaimer = styled.ul`
    font-size: ${({ theme }) => theme.font.size.xxs};
    margin-left: 0rem;
    margin-top: 1rem;
`

export const Label = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    line-height: 1.25;
    font-size: ${({ theme }) => theme.font.size.xxs};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    border: 1px solid ${({ theme }) => theme.color.tellowBlue};
    color: ${({ theme }) => theme.color.tellowBlue};

    min-width: 150px;
    width: min-content;
    padding: 0.45rem 1rem;

    border-radius: 0.85rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    margin-top: 0;
    min-height: 2.75rem;

    &:empty {
        visibility: hidden;

        @media screen and (max-width: ${size.tabletL}) {
            display: none;
        }
    }
`

export const Copy = styled.p`
    font-size: ${(props) => (props.variant === 'sm' ? '.7rem' : '1rem')};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    color: ${({ theme }) => theme.color.tellowPurple};
    text-align: center;

    width: 50%;
    margin-left: auto;
`

export const OverlayedNotification = styled(motion.span)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    pointer-events: none;

    width: calc(100% - 2rem);
    background: #1d1d1d;
    max-width: 350px;
    position: fixed;
    bottom: 1rem;
    left: 50%;

    padding: 0.65rem 0;
    transform: translateX(-50%);
    z-index: ${(props) => props.theme.zIndex.notification};

    @keyframes tilt {
        0% {
            transform: translateX(0) rotate(0);
        }
        100% {
            transform: translateX(-10px) rotate(-10deg);
        }
    }

    > svg,
    > p {
        color: ${({ theme }) => theme.color.tellowGray100};
    }

    > svg {
        animation: tilt alternate infinite 650ms ease-in-out;
        margin-right: 1.25rem;
        height: 1.125rem;
        width: 1.125rem;
    }

    > p {
        font-size: ${({ theme }) => theme.font.size.xs};
        font-weight: ${({ theme }) => theme.font.weight.semibold};
        margin: 0;
    }

    @media screen and (max-width: ${size.tabletL}) {
        display: none;
    }
`
