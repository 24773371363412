/* eslint-disable react/no-array-index-key */
import React, { forwardRef, useImperativeHandle } from 'react'
import tw from 'twin.macro'
import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import plandataNL from './data/plan-data'
import plandataEN from './data/plan-data.en'
import { useInView, useTranslate } from '../../../hooks'
import 'tippy.js/dist/tippy.css'
import { HideOnMobile } from './styled'
import { size } from '../../../utils/styled-components/breakpoints'

const ScalableCheck = styled.svg`
    --size: clamp(0.5rem, 5vw + 0.15rem, 1.5rem);
    color: ${({ theme }) => theme.color.tellowPurple};
    height: var(--size);
    width: var(--size);
`

const CheckWrapper = styled.div`
    display: grid;
    place-content: center;
`

/**
 * HeroIcon check icon.
 */
const Check = () => (
    <CheckWrapper>
        <ScalableCheck xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
            />
        </ScalableCheck>
    </CheckWrapper>
)

const Info = forwardRef((_, ref) => (
    <button ref={ref} type="button" css={tw`cursor-help! ml-4 appearance-none! bg-white border-none! flex items-center justify-center`}>
        <svg css={tw`text-gray-300 h-3.5 w-3.5`} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(1.2,0,0,1.2,0,0)">
                <path
                    d="M0.500 5.000 A4.500 4.500 0 1 0 9.500 5.000 A4.500 4.500 0 1 0 0.500 5.000 Z"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.250 7.250 A0.750 0.750 0 1 0 5.750 7.250 A0.750 0.750 0 1 0 4.250 7.250 Z"
                    fill="currentColor"
                    stroke="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M5,5A1.25,1.25,0,1,0,4,3" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    </button>
))
Info.displayName = 'Info Icon'

/**
 * All plans should be
 * kept in this array.
 *
 * Please mind that the
 * order matters.
 *
 * ('featureInPlan' maps it).
 */
const plans = [{ name: 'Gratis' }, { name: 'Basis' }, { name: 'Plus' }, { name: 'Compleet' }]

/**
 * All styles are
 * collected in
 * this object.
 */
const style = {
    wrap: tw`block max-w-7xl py-15 mx-auto px-4 sm:px-6 lg:px-8`,
    card: tw`p-2 sm:p-8 rounded-xl bg-white`,
    entry: tw`text-white grid items-center`,
    row: tw`text-white grid grid-cols-4`,
    section: tw`text-gray-800 mb-0 font-bold text-base antialiased tracking-tight`,
    strong: tw`text-gray-400 antialiased font-bold text-base!`,
    p: tw`text-gray-800 text-xxs sm:text-xs m-0 antialiased`,
    grid: tw`grid`,
}

export const CustomTippy = styled(Tippy)`
    background: ${({ theme }) => theme.color.tellowWhite} !important;
    color: ${({ theme }) => theme.color.tellowPurple} !important;
    box-shadow: ${({ theme }) => theme.smoothShadow} !important;
    font-weight: 500;

    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    .tippy-content {
        margin-bottom: 0.2rem;
    }

    &[data-placement^='top'] > .tippy-arrow::before {
        border-top-color: ${({ theme }) => theme.color.tellowGray100} !important;
    }
    &[data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: ${({ theme }) => theme.color.tellowGray100} !important;
    }
`

export const HeadlessTippy = ({ text }) => (
    <CustomTippy content={text}>
        <Info />
    </CustomTippy>
)

const MaxWidthArranger = styled.div`
    > p:first-of-type {
        white-space: normal;
        text-align: left;
        max-width: 30ch;
    }
`

/**
 * Features maps all
 * the 'fns' / function names
 * of a plan into the DOM.
 */
const Features = ({ f }) => (
    <div css={style.entry}>
        {f.fns.map(({ title, tooltip }, idx) => (
            <MaxWidthArranger key={`feature-${idx}`} css={tw`flex justify-between items-center text-center border border-b-transparent border-solid`}>
                <p css={style.p}>{title}</p>
                {tooltip && <HeadlessTippy text={tooltip} />}
            </MaxWidthArranger>
        ))}
    </div>
)

/**
 * Check if feature is in plan from plans array.
 */
const featureInPlanStyle = {
    unlimited: tw`flex items-center justify-center relative mb-0 text-xxs md:text-xs font-medium antialiased text-tellow-blue py-4`,
    dash: tw`flex relative mb-0 py-4`,
}
const featureInPlan = (yesNoUnlimited) =>
    yesNoUnlimited.map((yesnomaybe) => {
        switch (yesnomaybe) {
            case true:
                return <Check />
            case false:
                return <p css={featureInPlanStyle.dash}>{null}</p>
            default:
                return <p dangerouslySetInnerHTML={{ __html: yesnomaybe }} css={featureInPlanStyle.unlimited} />
        }
    })

const MinHeight = styled.div`
    min-height: ${(props) => props.height};
    white-space: nowrap;
    text-align: center;

    p {
        display: block;
        white-space: pre-line;
        word-break: break-word;
    }

    @media screen and (max-width: 900px) {
        > *:nth-child(odd) {
            background: ${({ theme }) => theme.color.tellowGray200};
        }
    }

    @media screen and (max-width: 900px) {
        span {
            display: none;
        }
    }
`

/**
 * Plans map the 'plans'
 * array into the DOM,
 * and adds either a
 * cross or a check.
 */
const Plans = ({ p }) => (
    <div css={tw`col-span-4`}>
        {p.fns.map(({ activeInPlans }, index) => (
            <MinHeight height="3.5rem" key={`plan-${index}`} css={[style.row, tw`border border-b-tellow-gray-200 border-solid`]}>
                {/* Take the passed element, and assign it a key, to prevent console warnings. */}
                {featureInPlan(activeInPlans).map((item, idx) => React.cloneElement(item, { key: `item-${idx}` }))}
            </MinHeight>
        ))}
    </div>
)

const OptionalStickyElement = styled.div`
    position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
    align-items: center;
    padding-top: 5.5rem;
    min-height: 10rem;
    display: grid;
    z-index: 10;
    top: 0;

    @media screen and (max-width: ${size.mobileL}) {
        display: none;
    }
`

const ShadowBar = styled(motion.div)`
    pointer-events: none;
    user-select: none;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    width: 100vw;
    height: 12rem;
    box-shadow: ${({ theme }) => theme.smoothShadow};
    background: ${({ theme }) => theme.color.tellowWhite};

    @media screen and (max-width: ${size.mobileL}) {
        display: none;
    }
`

const StickyPlanHeader = styled.span`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
        margin: 0;

        &:first-of-type {
            font-weight: 500;
            text-transform: capitalize;
        }

        &:last-of-type {
            opacity: 0.5;
            line-height: 1.5;
            font-weight: 400;
            font-size: 0.825rem;
            color: ${({ theme }) => theme.color.tellowBlack};
        }
    }
`

const StickyHeader = ({ col, isMonthly }) => {
    const { t, isDutch } = useTranslate('features')
    const { ref, inView } = useInView({
        rootMargin: '0px 0px -100% 0px',
        threshold: 0,
    })

    return (
        <>
            <OptionalStickyElement sticky={inView} ref={ref} style={{ gridTemplateColumns: col }}>
                <h3 style={{ marginBottom: 0 }}>{t.features}</h3>
                {Object.entries(isDutch ? plandataNL : plandataEN).map(([key, value], idx) => (
                    <StickyPlanHeader key={`sticky-header-${idx}`}>
                        <p>{key}</p>
                        <p>
                            {String((isMonthly ? value.priceMonthly : value.priceYearly) / 100).replace('.', ',')} {t.perMonth}
                        </p>
                    </StickyPlanHeader>
                ))}
            </OptionalStickyElement>
            <ShadowBar
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{
                    duration: 0.125,
                }}
            />
        </>
    )
}

/**
 * The actual template
 * to render to the DOM.
 */
const FeatureComparison = forwardRef(({ id, features, isMonthly }, ref) => {
    const gridTemplateColumns = `2fr repeat(${plans.length}, 1fr)`

    useImperativeHandle(ref, () => ({
        scrollIntoView: () => {
            ref.current.scrollIntoView({ behavior: 'smooth' })
        },
    }))

    return (
        <section id={id} ref={ref} css={style.wrap}>
            <div css={style.card}>
                {/* Header */}
                {/* <HideOnMobile> */}
                <StickyHeader col={gridTemplateColumns} isMonthly={isMonthly} />
                {/* </HideOnMobile> */}

                {/* Features */}
                {features?.map((ftr, idx) => (
                    <>
                        <div key={`feature-section-${idx}`}>
                            <h3 css={[style.section, tw`col-span-4 pt-10`]}>{ftr.section}</h3>
                        </div>
                        <div key={`feature-body-${idx}`} style={{ gridTemplateColumns }} css={style.grid}>
                            <Features key={`features/${idx}`} f={ftr} />
                            <Plans key={`plans/${idx}`} p={ftr} />
                        </div>
                    </>
                ))}
            </div>
        </section>
    )
})

FeatureComparison.displayName = 'Comparison Matrix'

export default FeatureComparison
